var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[(_vm.isLoading)?_c('trac-loading'):_c('div',[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"bg-blue-800 py-3 px-6 rounded-md text-white",on:{"click":function($event){return _vm.$router.push({
            name: 'Permissions',
          })}}},[_vm._v(" < Back ")])]),_c('h1',{staticClass:"text-2xl my-5 font-bold text-blue-800"},[_vm._v("Departments")]),_c('div',{staticClass:"flex flex-wrap xl:-mx-1"},_vm._l((_vm.filterDepartments),function(department,i){return _c('div',{key:i,staticClass:"w-full xl:my-1 xl:px-1 xl:w-1/2 mb-50"},[_c('div',{staticClass:"border-2 border-gray-200 bg-blue-50 p-5 rounded-md cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              name: 'PermissionsDepartmentById',
              params: {
                id: department._id,
                name: department.department,
              },
            })}}},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"font-bold"},[_vm._v(_vm._s(department.department))]),_c('svg',{staticClass:"bi bi-chevron-right",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"}})])])])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }