<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="flex">
        <button
          class="bg-blue-800 py-3 px-6 rounded-md text-white"
          @click="
            $router.push({
              name: 'Permissions',
            })
          "
        >
          < Back
        </button>
      </div>
      <h1 class="text-2xl my-5 font-bold text-blue-800">Departments</h1>

      <div class="flex flex-wrap xl:-mx-1">
        <div
          class="w-full xl:my-1 xl:px-1 xl:w-1/2 mb-50"
          v-for="(department, i) in filterDepartments"
          :key="i"
        >
          <!-- Column Content -->
          <div
            class="border-2 border-gray-200 bg-blue-50 p-5 rounded-md cursor-pointer"
            @click="
              $router.push({
                name: 'PermissionsDepartmentById',
                params: {
                  id: department._id,
                  name: department.department,
                },
              })
            "
          >
            <div class="flex justify-between">
              <div class="font-bold">{{ department.department }}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["GET_ALL_DEPARTMENTS"]),
    filterDepartments() {
      return this.GET_ALL_DEPARTMENTS.data;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch("FETCH_DEPARTMENTS");
      this.isLoading = false;
    } catch (error) {}
  },
  methods: {
    async createAdmin() {
      let payload = {
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        email: this.email.trim(),
        adminUserType: this.adminUserType,
        Department: this.assignRole,
      };
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("CREATE_ADMIN", payload);
        this.isLoading = false;
        if (res.status) {
          this.successMssg = true;
          // this.firstName = "";
          // this.lastName = "";
          // this.email = "";
          // this.selectLocation = "";
          // this.assignRole = "";
        } else {
          this.successMssg = false;
          this.errorMssg = true;
        }
      } catch (error) {}
    },
    closePromptModal() {
      this.promptModal = false;
      this.$router.push({ name: "Admin" });
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.selectLocation = "";
      this.assignRole = "";
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
